export const PRODUCT_PARTNER_OPTIONS = [
  {
    value: null,
    text: 'Chọn đối tác',
  },
  {
    value: 'microsoft-esd',
    text: 'Microsoft ESD',
  },
  {
    value: 'vieon-sub',
    text: 'Vieon SUB',
  },
];

export const PRODUCT_PARTNER_TABLE_FIELDS = [
  {
    key: 'sku',
    label: 'Mã sản phẩm',
    sortable: false,
    thStyle: {
      textAlign: 'left',
      width: '20%'
    }
  },
  {
    key: 'name',
    label: 'Sản phẩm',
    sortable: false,
    thStyle: {
      textAlign: 'left',
      color: 'rgb(24, 28, 50)',
      width: '60%',
    },
  },
  {
    key: 'action',
    label: 'Hành động',
    sortable: false,
    thStyle: {
      textAlign: 'center',
      color: 'rgb(24, 28, 50)',
      width: '20%',
    },
    tdClass: 'text-center',
  },
];
